<!--
 * @Author: your name
 * @Date: 2020-12-15 16:03:49
 * @LastEditTime: 2021-12-01 16:58:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT_Web_test\src\views\salesBusiness\marketInforPrice\supplementPop.vue
-->
<template>
    <div class="marketInforPrice-maintainPop" v-loading="loading">
        <table class="main-table">
            <thead>
                <tr>
                    <th colspan="4" class="main-table-tit">
                        基本信息
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="row-tit">
                        信息价发布地区：
                    </td>
                    <td>{{ periodData.period.region_name }}</td>
                    <td class="row-tit">
                        维护时间：
                    </td>
                    <td>{{ periodData.period.created }}</td>
                </tr>
                <tr>
                    <td class="row-tit">
                        更新频率：
                    </td>
                    <td>{{ periodData.period.update_frequency }}</td>
                    <td class="row-tit">
                        更新日期：
                    </td>
                    <td>{{ periodData.period.update_date }}</td>
                </tr>
                <tr>
                    <td class="row-tit">
                        累计期次：
                    </td>
                    <td @click="viewCumulativePeriods" style="cursor: pointer;">
                        <span :class="{'linkClick': isReadOnly == false}">{{ periodData.period.current_period_total }}</span>
                    </td>
                    <td class="row-tit">
                        合同数量：
                    </td>
                    <td @click="openContract" style="cursor: pointer;">
                        <span :class="{'linkClick': isReadOnly == false}">
                            {{ periodData.period.contract_quantity }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td class="row-tit">
                        维护期次：
                    </td>
                    <td>{{ periodData.period.current_period }}</td>
                    <td class="row-tit">
                        期次月份：
                    </td>
                    <td style="text-indent:0;">
                        <el-date-picker
                            v-model="timeVal"
                            type="month"
                            placeholder="选择月"
                            value-format="yyyy-MM"
                            @change="confirmSelection"
                        >
                        </el-date-picker>
                    </td>
                </tr>
                <tr>
                    <td class="row-tit">
                        维护人：
                    </td>
                    <td>{{ periodData.period.maintainer }}</td>
                    <td class="row-tit">
                        审核人：
                    </td>
                    <td>{{ periodData.period.reviewer }}</td>
                </tr>
                <tr>
                    <td class="row-tit">
                        状态：
                    </td>
                    <td colspan="3">
                        <span v-if="periodData.period.state == 1">已保存</span>
                        <span v-if="periodData.period.state == 2">已提交</span>
                        <span v-if="periodData.period.state == 3">已确认</span>
                        <span v-if="periodData.period.state == 4">已退回</span>
                    </td>
                </tr>
                <tr>
                    <td colspan="4" class="split-row">
                        <span class="fl">市场价格信息</span>
                        <div class="fr">
                            <span
                                class="table-btn readpre"
                                @click="readPreviousIssue"
                            >
                                读取上期
                            </span>
                            <span
                                class="table-btn chose"
                                @click="selectMarketInfoPrice"
                            >选择</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <el-table
            :data="periodData.periodExtendList"
            style="width: 100%"
            height="calc(100% - 3.8rem)"
        >
            <el-table-column
                type="index"
                width="55"
                label="序号"
            >
            </el-table-column>
            <el-table-column
                prop="info_price_number"
                label="编码"
                width="160"
            >
            </el-table-column>
            <el-table-column
                prop="info_price_name"
                label="产品名称"
                width="160"
            >
            </el-table-column>
            <el-table-column label="规格型号">
                <el-table-column
                    prop="specification_mode_name"
                    label="名称"
                >
                </el-table-column>
                <el-table-column
                    prop="product_mark_num"
                    label="产品标号"
                >
                </el-table-column>
                <el-table-column
                    prop="additional_model"
                    label="附加型号"
                >
                </el-table-column>
                <el-table-column
                    prop="coarse_aggregate"
                    label="粗骨料"
                >
                </el-table-column>
                <el-table-column
                    prop="fine_aggregate"
                    label="细骨料"
                >
                </el-table-column>
                <el-table-column
                    prop="pour_mode"
                    label="浇筑方式"
                >
                </el-table-column>
            </el-table-column>
            <el-table-column
                prop="measuring_unit"
                label="单位"
            >
            </el-table-column>
            <el-table-column
                label="含税单价"
            >
                <template slot-scope="scope">
                    <el-input
                        v-model="scope.row.including_tax_price"
                        size="mini"
                        @input="onInput"
                        @change="priceEditing(scope.row, scope.$index)"
                    ></el-input>
                </template>
            </el-table-column>
            <el-table-column
                label="税率"
            >
                <template slot-scope="scope">
                    <el-input
                        v-model="scope.row.tax_rate"
                        size="mini"
                        @input="onInput"
                        @change="priceEditing(scope.row, scope.$index)"
                    ></el-input>
                </template>
            </el-table-column>
            <el-table-column
                label="除税单价(元)"
            >
                <template slot-scope="scope">
                    <el-input
                        v-model="scope.row.tax_free_price"
                        size="mini"
                        @input="onInput"
                        @change="priceEditing(scope.row, scope.$index)"
                    ></el-input>
                </template>
            </el-table-column>
        </el-table>
        <div class="btn-con">
            <span class="btn-item" @click="preservation">保存</span>
            <span class="btn-item close-btn" @click="close">关闭</span>
        </div>
    </div>
</template>

<script>
import store from '@/store';
import NP from 'number-precision';

export default {
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            loading: false,
            // 期次月份
            timeVal: '',
            // 期次数据
            periodData: {
                period: {
                    info_price_period_number: '',
                    info_price_regional_number: '',
                    region_code: '',
                    region_name: '',
                    update_frequency: '',
                    update_frequency_code: '',
                    update_date: 0,
                    current_period: '',
                    current_period_year: 0,
                    current_period_month: 0,
                    period_month: 0,
                    period_year: 0,
                    state: 0,
                    maintainer: '',
                    reviewer: '',
                    created: '',
                    current_period_total: 0,
                    current_period_begin_date: '',
                    current_period_end_date: '',
                    contract_quantity: 0,
                },
                periodExtendList: [],
            },
            // 是否是查看场景（查看场景，不显示按钮）
            isReadOnly: false,
        };
    },
    watch: {},
    computed: {},
    created() {
        // this.extr.isReadOnly查看（查看场景，不显示按钮）
        if (this.extr && this.extr.isReadOnly) {
            this.isReadOnly = true;
        }
        // 修改
        if (this.extr && this.extr.isModify) {
            // 初始化修改数据
            this.initModifyData();
        } else {
            // 初始化期次基本信息数据
            this.getPeriodData();
        }
    },
    mounted() {},
    methods: {
        // 初始化修改数据
        initModifyData() {
            this.loading = true;
            this.$axios
                .get(`/interfaceApi/sale/info_price/get_period_by_number?periodNumber=${this.extr.row.info_price_period_number}`)
                .then(res => {
                    if (res.period) {
                        this.periodData.period = res.period;

                        this.timeVal = `${res.period.period_year}-${res.period.period_month}`;
                    }
                    if (res.periodExtendList) {
                        this.periodData.periodExtendList = res.periodExtendList;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 初始化期次基本信息数据
        getPeriodData() {
            this.loading = true;
            this.$axios
                .get(`/interfaceApi/sale/info_price/get_supplement_price_regional?regionalNumber=${this.extr.regional_number}`)
                .then(res => {
                    this.periodData.period = res;
                    // 维护人默认当前登录人
                    this.periodData.period.maintainer = this.$takeTokenParameters('Name');
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 选择期次月份
        confirmSelection() {
            if (this.timeVal === '' || !this.timeVal) {return;}
            const row = this.timeVal.split('-');
            this.periodData.period.period_year = row[0];
            this.periodData.period.period_month = row[1];
        },

        // 读取上期
        readPreviousIssue() {
            if (this.timeVal === '' || !this.timeVal) {
                this.$message({
                    message: '请先选择期次月份！',
                    type: 'warning',
                });
            } else {
                this.loading = true;
                this.$axios
                    .get('/interfaceApi/sale/info_price/get_supplement_previous_period_extend?'
                   + `regionalNumber=${this.periodData.period.info_price_regional_number}`
                   + `&period_year=${this.periodData.period.period_year}`
                   + `&period_month=${this.periodData.period.period_month}`
                    )
                    .then(res => {
                        if (res && res.length > 0) {
                            this.periodData.periodExtendList = res;
                        } else {
                            this.periodData.periodExtendList = [];
                        }
                        this.loading = false;
                    })
                    .catch(error => {
                        this.loading = false;
                        this.$message.error(error.ErrorCode.Message);
                    });
            }
        },
        // 选择市场信息价
        selectMarketInfoPrice() {
            this.$toast({
                title: true,
                text: '信息价产品',
                type: 'eject',
                width: '15rem',
                height: '80%',
                t_url: 'salesBusiness/marketInforPrice/chooseInformationPrice',
                viewPosition: 'view',
                extr: {
                    regionalNumber: this.periodData.period.info_price_regional_number,
                    selectCallBack: this.selectMarketInfoPriceCallBack,
                },
            });
        },
        // 选择市场信息价回调
        selectMarketInfoPriceCallBack(data) {
            if (data.length === 0) { return; }
            const reArr = [];
            data.forEach(k => {
                this.periodData.periodExtendList.forEach(item => {
                    if (item.compose_code === k.compose_code) {
                        reArr.push(k);
                    }
                });
            });
            const row = data.filter(item => {
                return !reArr.find(k => item.Id === k.Id);
            });
            if (reArr.length > 0) {
                let text = '';
                reArr.forEach(item => {
                    text += item.specification_mode_name + ' ';
                });
                this.$message({
                    message: `${text}已存在！ `,
                    type: 'warning',
                });
            }
            if (row.length > 0) {
                this.periodData.periodExtendList.push(...row);
            }
        },
        // 保存
        preservation() {
            this.loading = true;
            this.$axios
                .post('/interfaceApi/sale/info_price/supplement_save_period', this.periodData)
                .then(res => {
                    let text = '';
                    if (!this.periodData.period.info_price_period_number || this.periodData.period.info_price_period_number === '') {
                        text = '保存成功！';
                    } else {
                        text = '修改成功！';
                    }
                    this.extr.table.getTableList();
                    // 提交流程刷新列表
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });

                    this.$message({
                        message: text,
                        type: 'success',
                    });

                    this.periodData = res;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 累计期次
        viewCumulativePeriods() {
            if (this.isReadOnly === false && this.periodData.period.current_period_total) {
                this.$toast({
                    title: true,
                    text: '查看累计期次',
                    type: 'eject',
                    width: '15rem',
                    height: '75%',
                    t_url: 'salesBusiness/marketInforPrice/showAggregatePeriod',
                    extr: {
                        tips: '查看累计期次',
                        regionalNumber: this.periodData.period.info_price_regional_number,
                        periodNumber: this.periodData.period.info_price_period_number,
                    },
                });
            }
        },
        // 查看合同
        openContract() {
            if (this.isReadOnly === false) {
                this.$toast({
                    title: true,
                    type: 'eject',
                    width: '70%',
                    t_url: 'tableList/index',
                    extr: {
                        code: {
                            TableCode: 'xxj-htlb',
                            QueryString: `period_number=${this.extr.row.info_price_period_number}`,
                        },
                    },
                });
            }
        },
        // 关闭
        close() {
            this.$parent.hide();
        },
        onInput() {
            this.$forceUpdate();
        },

        // 价格编辑
        priceEditing(item, index) {
            const res = JSON.parse(JSON.stringify(item));
            if (!res.including_tax_price && res.tax_rate && res.tax_free_price) {
                // 含税单价 = 除税单价 *（1 + 税率）
                res.including_tax_price = NP.times(res.tax_free_price, NP.plus(1, NP.divide(res.tax_rate, 100))).toFixed(2);
            } else if (!res.tax_free_price && res.including_tax_price && res.tax_rate) {
                // 除税单价 = 含税单价 / (1 + 税率）
                res.tax_free_price = NP.divide(res.including_tax_price, NP.plus(1, NP.divide(res.tax_rate, 100))).toFixed(2);
            }
            this.$set(this.periodData.periodExtendList, index, res);
        },
    },
};
</script>
<style lang="stylus" scoped>
.marketInforPrice-maintainPop
    height 100%
    padding .14rem
    .btns
        height 0.32rem
        margin-bottom 0.13rem
        .el-button
            width 0.9rem
            height 0.32rem
            line-height 0.3rem
            padding 0
    .main-table
        width 100%
        border-collapse collapse
        border 1px solid #D7D7D7
        th,td
            border 1px solid #D7D7D7
            line-height .36rem
            font-size .16rem
            text-indent .15rem
            .el-select
                width 100%
                .el-input__inner
                    border none
                    text-indent 0
            .linkClick
                color blue
                text-decoration underline
        th
            width 1.5rem
        td
            width 4rem
            .el-date-editor
                width 100% !important
                vertical-align top
        .main-table-tit
            background #EDF0F5
            font-size .18rem
            color #022782
            font-weight normal
        .row-tit
            background #F6F8FC
            color #022782
            width 2rem
        .split-row
            background #EDF0F5
            line-height .40rem
            // display flex
            .table-btn
                padding .05rem .15rem
                background #1577D2
                border-radius .04rem
                color #fff
                margin-right .2rem
                font-size .14rem
            span
                color #022782
        .fl
            float left
        .fr
            float right
    .el-table--border
        th
            border-right 1px solid #D7D7D7
            border-bottom 1px solid #D7D7D7
        td
            border-right 1px solid #D7D7D7
    .el-table
        th,td
            padding  0
            line-height 0.36rem
            text-align center
        th
            &.is-leaf
                border-bottom 1px solid #D7D7D7
        td
            border-bottom 1px solid #D7D7D7
        &::before
            background-color #d7d7d7
        thead
            &.is-group
                th
                    background #F6F8FC
                    color #022782
                    font-weight normal
                    font-size .16rem
        .cell
            padding 0
    .el-table--border, .el-table--group
        border 1px solid #D7D7D7
        border-right none
        border-bottom none
        border-top none
        &::after
            background-color #d7d7d7
    .btn-con
        position absolute
        bottom .2rem
        left 0
        width 100%
        text-align center
        .btn-item
            width 2.20rem
            height .40rem
            line-height .4rem
            background #1577D2
            border-radius 1px
            color #fff
            display inline-block
            margin 0 .3rem
            cursor pointer
        .close-btn
            border 1px solid #1577D2
            background #ffffff
            color #1577D2
</style>
